<template>
    <div class="frame-content">
        <div class="layout-left" style="width:260px;">
            <div class="filter-tree">
                <el-input
                    placeholder="搜索"
                    v-model="filterTreeText"
                    size="small">
                </el-input>
            </div>
            <div class="left-box-btn">
                <el-button type="primary" size="mini" @click="handleAddBuilding" :disabled="$store.state.org.type != 2">新增楼栋</el-button>
                <div class="btn-act">
                    <el-dropdown v-if="$store.state.org.type == 2" trigger="click" @command="handleBatchCommand">
                        <span class="el-dropdown-link"><i class="el-icon-more el-icon--right"></i></span>
                        <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item command="1">启用</el-dropdown-item>
                            <el-dropdown-item command="2">禁用</el-dropdown-item> -->
                            <el-dropdown-item command="3">批量新增</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="left-box-wrap left-box-wrap2" v-loading="treeLoading">
                <Scrollbar style="height:100%;">
                    <div class="left-box left-box2">
                        <el-tree
                            ref="leftTree"
                            check-strictly
                            :expand-on-click-node="false"
                            :data="treeData"
                            :filter-node-method="filterNode"
                            default-expand-all
                            node-key="id"
                            :props="{ children: 'children', label: 'name' }"
                            @node-click="handleNodeClick">
                            <div slot-scope="{ node, data }">
                                <div class="tree-name">{{ data.name }}</div>
                                <div v-if="data.display == 0" class="tree-status"><el-tag type="danger" size="mini">停用</el-tag></div>
                                <div v-if="data.type == 0" class="tree-act" @click.stop="handleTreeActClick">
                                    <el-dropdown trigger="click" @command="handleBuildingCommand($event, data)">
                                        <span class="el-dropdown-link"><i class="el-icon-more el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="1">编辑楼栋</el-dropdown-item>
                                            <el-dropdown-item v-if="data.display == 0" command="2">启用楼栋</el-dropdown-item>
                                            <el-dropdown-item v-if="data.display == 1" command="2">停用楼栋</el-dropdown-item>
                                            <el-dropdown-item command="3">删除楼栋</el-dropdown-item>
                                            <el-dropdown-item command="4">添加楼层</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div v-if="data.type == 1" class="tree-act" @click.stop="handleTreeActClick">
                                    <el-dropdown trigger="click" @command="handleStoreyCommand($event, data)">
                                        <span class="el-dropdown-link"><i class="el-icon-more el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="1">编辑楼层</el-dropdown-item>
                                            <el-dropdown-item v-if="data.display == 0" command="2">启用楼层</el-dropdown-item>
                                            <el-dropdown-item v-if="data.display == 1" command="2">停用楼层</el-dropdown-item>
                                            <el-dropdown-item command="3">删除楼层</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </el-tree>
                    </div>
                </Scrollbar>
            </div>
        </div>
        <div class="frame-content-wrap layout-right" style="margin-left:260px;">
            <div class="filter">
                <div class="inline">
                    <el-input v-model="filters.name" placeholder="宿舍编号" size="small"></el-input>
                </div>
                <div class="inline ml-8">
                    <el-select v-model="filters.status" clearable placeholder="宿舍状态" size="small">
                        <el-option label="待分配" value="1"></el-option>
                        <el-option label="使用中" value="2"></el-option>
                        <el-option label="停用" value="3"></el-option>
                    </el-select>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd" :disabled="$store.state.org.type != 2">新增房间</el-button>
                    </div>
                    <!-- <div class="inline mr-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除房间</el-button>
                    </div> -->
                    <!-- <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleImport">导入房间</el-button>
                    </div>-->
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleExportQr">导出二维码</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="60">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="宿舍编号"
                            align="left">
                        </el-table-column>
                        <el-table-column
                            prop="roomType"
                            label="宿舍类型"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <template v-if="scope.row.roomType == 1">男宿</template>
                                <template v-if="scope.row.roomType == 2">女宿</template>
                                <template v-if="scope.row.roomType == 3">夫妻房</template>
                                <template v-if="scope.row.roomType == 4">仓库</template>
                                <template v-if="scope.row.roomType == 5">办公室</template>
                                <template v-if="scope.row.roomType == 6">隔离室</template>
                                <template v-if="scope.row.roomType == 7">沃舟自用房</template>
                                <template v-if="scope.row.roomType == 8">其他</template>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="property"
                            label="宿舍资产">
                        </el-table-column> -->
                        <el-table-column
                            prop="status"
                            label="宿舍状态"
                            width="100">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status == 1 && scope.row.supplierId == 0" type="primary" disable-transitions>待分配</el-tag>
                                <el-tag v-if="scope.row.status == 1 && scope.row.supplierId != 0" type="info" disable-transitions>使用中</el-tag>
                                <el-tag v-if="scope.row.status == 0" type="danger" disable-transitions>停用</el-tag>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="checkinNum"
                            label="可入住人数"
                            width="100">
                        </el-table-column> -->
                        <el-table-column
                            prop="createTime"
                            label="创建时间"
                            align="center"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="remark"
                            align="left"
                            label="备注">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="280">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="warning"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                <el-button
                                    v-if="scope.row.status === 1"
                                    size="mini"
                                    type="warning"
                                    @click="handleDisable(scope.$index, scope.row, 0)">停用</el-button>
                                <el-button
                                    v-else
                                    size="mini"
                                    type="success"
                                    @click="handleDisable(scope.$index, scope.row, 1)">启用</el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleCheckLog(scope.$index, scope.row)">日志</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            title="批量新增"
            v-if="addBatchDialog.create"
            :visible.sync="addBatchDialog.visible"
            :close-on-click-modal="false"
            @closed="handleAddBatchClosed"
            class="custom-dialog mini-dialog">
            <BatchAdd @done="handleAddBatchDone"></BatchAdd>
        </el-dialog>

        <el-dialog
            title="新增楼栋"
            v-if="addBuildingDialog.create"
            :visible.sync="addBuildingDialog.visible"
            :close-on-click-modal="false"
            @closed="handleAddBuildingClosed"
            class="custom-dialog mini-dialog">
            <BuildingAdd @done="handleAddBuildingDone"></BuildingAdd>
        </el-dialog>

        <el-dialog
            title="编辑楼栋"
            v-if="editBuildingDialog.create"
            :visible.sync="editBuildingDialog.visible"
            :close-on-click-modal="false"
            @closed="handleEditBuildingClosed"
            class="custom-dialog mini-dialog">
            <BuildingEdit :params="editBuildingDialog.params" @done="handleEditBuildingDone"></BuildingEdit>
        </el-dialog>

        <el-dialog
            title="新增楼层"
            v-if="addStoreyDialog.create"
            :visible.sync="addStoreyDialog.visible"
            :close-on-click-modal="false"
            @closed="handleAddStoreyClosed"
            class="custom-dialog mini-dialog">
            <StoreyAdd :params="addStoreyDialog.params" @done="handleAddStoreyDone"></StoreyAdd>
        </el-dialog>

        <el-dialog
            title="编辑楼层"
            v-if="editStoreyDialog.create"
            :visible.sync="editStoreyDialog.visible"
            :close-on-click-modal="false"
            @closed="handleEditStoreyClosed"
            class="custom-dialog mini-dialog">
            <StoreyEdit :params="editStoreyDialog.params" @done="handleEditStoreyDone"></StoreyEdit>
        </el-dialog>

        <el-dialog
            title="新增房间"
            v-if="addDialog.create"
            :visible.sync="addDialog.visible"
            :close-on-click-modal="false"
            @closed="handleAddClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <RoomAdd :building-tree="selTreeData" :params="addDialog.params" @done="handleAddDone"></RoomAdd>
        </el-dialog>

        <el-dialog
            title="编辑房间"
            v-if="editDialog.create"
            :visible.sync="editDialog.visible"
            :close-on-click-modal="false"
            @closed="handleEditClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <RoomEdit :building-tree="selTreeData" :params="editDialog.params" @done="handleEditDone"></RoomEdit>
        </el-dialog>

        <el-dialog
            title="房间日志"
            v-if="checkLogDialog.create"
            :visible.sync="checkLogDialog.visible"
            :close-on-click-modal="false"
            @closed="handleCheckLogClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <CheckLog :params="checkLogDialog.params" @done="handleCheckLogDone"></CheckLog>
        </el-dialog>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import Scrollbar from '@/components/Scrollbar'
import BuildingAdd from './BuildingAdd'
import BuildingEdit from './BuildingEdit'
import StoreyAdd from './StoreyAdd'
import StoreyEdit from './StoreyEdit'
import RoomAdd from './RoomAdd'
import RoomEdit from './RoomEdit'
import CheckLog from './CheckLog'
import BatchAdd from './BatchAdd'

export default {
    name: 'BuildingRoom',
    components: {
        Scrollbar,
        BuildingAdd,
        BuildingEdit,
        StoreyAdd,
        StoreyEdit,
        RoomAdd,
        RoomEdit,
        CheckLog,
        BatchAdd,
    },
    data() {
        return {
            selTreeData: [],
            treeData: [],
            treeLoading: false,

            nodeId: '',
            nodeData: {},
            filterTreeText: '',
            storeyId: '',

            filters: {
                name: '',
                status: '',
                useStatus: ''
            },

            dateRange: [],

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,

            addBatchDialog: {
                create: false,
                visible: false,
            },

            addBuildingDialog: {
                create: false,
                visible: false,
                params: {},
            },

            editBuildingDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addStoreyDialog: {
                create: false,
                visible: false,
                params: {},
            },

            editStoreyDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
                params: {},
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },

            checkLogDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTree();
    },
    watch: {
        filterTreeText(val) {
            this.$refs.leftTree.filter(val);
        },
    },
    methods: {
        ...common,
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        loadTree() {
            this.treeLoading = true;
            http.get('v1/build/tree').then(req => {
                this.treeLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    this.selTreeData = req.data.data;
                    this.treeData = [{
                        id: '0',
                        name: '全部',
                        children: req.data.data,
                    }];
                    this.$nextTick(() => {
                        if (this.treeData && this.treeData.length > 0 && this.$refs.leftTree) {
                            if (this.nodeId != '') {
                                let node = this.$refs.leftTree.getNode(this.nodeId);
                                if (!node) {
                                    this.nodeId = this.treeData[0].id;
                                    this.nodeData = this.treeData[0];
                                } else {
                                    if (node.level > 1) {
                                        this.storeyId = this.nodeId;
                                    }
                                }
                            } else {
                                this.nodeId = this.treeData[0].id;
                                this.nodeData = this.treeData[0];
                            }

                            this.$refs.leftTree.setCurrentKey(this.nodeId);

                            this.loadTable();
                        }
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.treeLoading = false;
                this.httpCatch(err);
            });
        },
        loadTable() {
            let {
                name,
                status,
                // useStatus,
            } = this.filters;

            let params = {};

            params.pid = this.nodeId;
            params.type = 2;

            this.pageNum = 1;

            this.tableLoading = true;
            http.get('v1/build/list', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    data = data.filter((item) => {
                        if (name != '' && item.name.indexOf(name) == -1) {
                            return false;
                        }

                        if (status != '') {
                            if (status == 1) {
                                if (!(item.status == 1 && item.supplierId == 0)) {
                                    return false;
                                }
                            } else if (status == 2) {
                                if (!(item.status == 1 && item.supplierId != 0)) {
                                    return false;
                                }
                            } else if (status == 3) {
                                if (item.status != 0) {
                                    return false;
                                }
                            }
                        }

                        // if (useStatus != '') {
                        //     if (useStatus == 1) {
                        //         if (item.checkin != 0) {
                        //             return false;
                        //         }
                        //     } else if (useStatus == 2) {
                        //         if (item.checkin >= item.checkinNum) {
                        //             return false;
                        //         }
                        //     } else if (useStatus == 3) {
                        //         if (item.checkin < item.checkinNum) {
                        //             return false;
                        //         }
                        //     }
                        // }

                        return true;
                    });

                    this.totalNum = data.length;

                    let startIndex = this.pageSize*(this.pageNum - 1);
                    let endIndex = startIndex + this.pageSize;

                    data = data.filter((_, index) => {
                        return index >= startIndex && index < endIndex;
                    });

                    data.forEach(item => {
                        item.createTime = item.createTime.substr(0, 16);
                    });

                    this.curPageNum = this.pageNum;

                    this.tableData = data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleTreeActClick() {},
        handleBatchCommand(command) {
            // let nodes = this.$refs.leftTree.getCheckedNodes();
            // let idList = nodes.map(x => x.id);
            // console.log( idList );
            if (command == "1") {
                //
            } else if (command == "2") {
                //
            } else if (command == "3") {
                this.handleAddBatch();
            } else {
                //
            }
        },
        handleBuildingCommand(command, data) {
            switch(command) {
                case "1":
                    this.handleEditBuilding(data);
                    break;
                case "2":
                    this.handleDisableBuilding(data);
                    break;
                case "3":
                    this.handleDeleteBuilding(data);
                    break;
                case "4":
                    this.handleAddStorey(data);
                    break;
                default:
                    break;
            }
        },
        handleStoreyCommand(command, data) {
            switch(command) {
                case "1":
                    this.handleEditStorey(data);
                    break;
                case "2":
                    this.handleDisableStorey(data);
                    break;
                case "3":
                    this.handleDeleteStorey(data);
                    break;
                default:
                    break;
            }
        },
        // handleNodeClick(data, node, vuecomponent) {
        handleNodeClick(data) {
            console.log(data)
            this.nodeId = data.id;
            this.nodeData = data;

            let node = this.$refs.leftTree.getNode(this.nodeId);
            if (node && node.level > 2) {
                this.storeyId = this.nodeId;
            } else {
                this.storeyId = '';
            }

            this.loadTable();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
        handleDetail(index, row) {
            this.detailDialog = { visible: true, params: row };
        },

        handleAddBatch() {
            this.addBatchDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addBatchDialog = { create: true, visible: true };
            });
        },
        handleAddBatchDone() {
            this.addBatchDialog = { create: true, visible: false };
            this.loadTree();
        },
        handleAddBatchClosed() {
            this.addBatchDialog = { create: false, visible: false };
        },

        handleAddBuilding() {
            this.addBuildingDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addBuildingDialog = { create: true, visible: true };
            });
        },
        handleAddBuildingDone() {
            this.addBuildingDialog = { create: true, visible: false };
            this.loadTree();
        },
        handleAddBuildingClosed() {
            this.addBuildingDialog = { create: false, visible: false };
        },

        handleEditBuilding(data) {
            this.editBuildingDialog = { create: true, visible: false, params: data };
            this.$nextTick(() => {
                this.editBuildingDialog = { create: true, visible: true, params: data };
            });
        },
        handleEditBuildingDone() {
            this.editBuildingDialog = { create: true, visible: false, params: {} };
            this.loadTree();
        },
        handleEditBuildingClosed() {
            this.editBuildingDialog = { create: false, visible: false, params: {} };
        },

        handleDisableBuilding(data) {
            let text;
            let status;
            if (data.display == 1) {
                status = 0;
                text = '禁用';
            } else if (data.display == 0) {
                status = 1;
                text = '启用';
            } else {
                this.showError('未知状态值！');
                return;
            }

            let id = data.id;

            this.confirm('确认'+text+'该楼栋？', function () {
                http.post('v1/build/status', { id: id, status: status }).then(req => {
                    if (this.httpThen(req)) {
                        return;
                    }
                    if (req.data.code == http.SUCCESS) {
                        this.loadTree();
                        this.showSuccess(text+'成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },

        handleDeleteBuilding(data) {
            let id = data.id;
            this.confirm('确认删除该楼栋？', function () {
                http.post('v1/build/delete', { id: id }).then(req => {
                    if (this.httpThen(req)) {
                        return;
                    }
                    if (req.data.code == http.SUCCESS) {
                        this.loadTree();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },

        handleAddStorey(data){
            this.addStoreyDialog = { create: true, visible: false, params: data };
            this.$nextTick(() => {
                this.addStoreyDialog = { create: true, visible: true, params: data };
            });
        },
        handleAddStoreyDone(){
            this.addStoreyDialog = { create: true, visible: false, params: {} };
            this.loadTree();
        },
        handleAddStoreyClosed(){
            this.addStoreyDialog = { create: false, visible: false, params: {} };
        },

        handleEditStorey(data){
            this.editStoreyDialog = { create: true, visible: false, params: data };
            this.$nextTick(() => {
                this.editStoreyDialog = { create: true, visible: true, params: data };
            });
        },
        handleEditStoreyDone(){
            this.editStoreyDialog = { create: true, visible: false, params: {} };
            this.loadTree();
        },
        handleEditStoreyClosed(){
            this.editStoreyDialog = { create: false, visible: false, params: {} };
        },

        handleDisableStorey(data) {
            let text;
            let status;
            if (data.display == 1) {
                status = 0;
                text = '禁用';
            } else if (data.display == 0) {
                status = 1;
                text = '启用';
            } else {
                this.showError('未知状态值！');
                return;
            }

            let id = data.id;

            this.confirm('确认'+text+'所选楼层？', function () {
                http.post('v1/build/status', { id: id, status: status }).then(req => {
                    if (this.httpThen(req)) {
                        return;
                    }
                    if (req.data.code == http.SUCCESS) {
                        this.loadTree();
                        this.showSuccess(text+'成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },

        handleDeleteStorey(data) {
            let id = data.id;
            this.confirm('确认删除该楼层？', function () {
                http.post('v1/build/delete', { id: id }).then(req => {
                    if (this.httpThen(req)) {
                        return;
                    }
                    if (req.data.code == http.SUCCESS) {
                        this.loadTree();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },

        handleAdd() {
            let params = { storeyId: this.storeyId };
            this.addDialog = { create: true, visible: false, params: params };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true, params: params };
            });
        },
        handleAddDone() {
            this.addDialog = { create: true, visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = { create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false };
        },

        handleDisable(index, row, status) {
            let text;
            if (status == 0) {
                text = '停用'
            } else if (status == 1) {
                text = '启用'
            } else {
                this.showError('未知状态值！');
                return;
            }

            let data;
            if (row) {
                data = { id: row.id, status: status };
            } else {
                let idarr = [];
                for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                    idarr.push(this.$refs.currentTable.selection[i].id);
                }
                data = { idList: idarr, status: status };
            }

            if (data.idList && data.idList.length == 0) {
                this.showError('请选择要'+text+'的房间！');
            } else {
                this.confirm('确认'+text+'所选房间？', function () {
                    http.post('v1/build/status', data).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess(text+'成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },

        handleDelete(index, row) {
            let id = row.id;
            this.confirm('确认删除该房间？', function () {
                http.post('v1/build/delete', { id: id }).then(req => {
                    if (this.httpThen(req)) {
                        return;
                    }
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },

        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的房间！');
            } else {
                this.confirm('确认删除所选房间？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/build/delete', { ids: idarr }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },

        handleCheckLog(index, row) {
            this.checkLogDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.checkLogDialog = { create: true, visible: true, params: row };
            });
        },
        handleCheckLogDone() {
            this.checkLogDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleCheckLogClosed() {
            this.checkLogDialog = { create: false, visible: false };
        },

        handleImport() {},
        handleExportQr() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要导出二维码的房间！');
            } else {
                let idarr = [];
                for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                    idarr.push(this.$refs.currentTable.selection[i].id);
                }
                let idListStr = idarr.join(',');

                let url = 'v1/build/exportQr?idList=' + idListStr;

                let da = new Date();
                let filename = this.getCurrentOrg().name
                    + "_房间二维码_"
                    + da.getFullYear()
                    + '' + (da.getMonth() + 1)
                    + '' + this.fix(da.getDate())
                    + '' + this.fix(da.getHours())
                    + '' + this.fix(da.getMinutes())
                    +".zip";

                http.download(url, { filename: filename, type: "application/zip" })

                // let url = http.BASE_URL + 'v1/build/exportQr?idList=' + idListStr + '&token=' + localStorage.getItem("token");
                // window.open(url);
            }
        },
    }
}
</script>

<style scoped>
</style>
