<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item
                        label="楼层"
                        prop="pid">
                        <SelectTree
                            ref="selectTree"
                            placeholder="请选择"
                            v-model="form.pid"
                            :data="buildingTree"
                            :tree-props="{ children: 'children', label: 'name' }"
                            default-expand-all
                            filterable
                            clearable
                            style="width:100%;"
                            size="small"
                            level-separator=" - "
                            :select-min-level="1">
                        </SelectTree>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="宿舍编号" prop="name">
                        <el-input type="text" v-model="form.name" size="small" maxlength="20" @blur="fieldTrim(form, 'name')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="宿舍类型" prop="roomType">
                        <el-select
                            v-model="form.roomType"
                            placeholder="请选择"
                            size="small"
                            style="width:100%;">
                            <el-option
                                v-for="item in roomTypeList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="可住人数" prop="checkinNum">
                        <el-select
                            v-model="form.checkinNum"
                            placeholder="请选择"
                            size="small"
                            style="width:100%;">
                            <el-option
                                v-for="item in checkinNumList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <!-- <el-input type="text" v-model="form.checkinNum" size="small" maxlength="10" @blur="fieldTrim(form, 'checkinNum')"></el-input> -->
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="宿舍资产" prop="property">
                        <el-select
                            v-model="form.property"
                            placeholder="请选择"
                            size="small"
                            multiple
                            style="width:100%;">
                            <el-option
                                v-for="item in propertyList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row> -->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="费用类型" prop="costTypeId">
                        <el-select
                            v-model="form.costTypeId"
                            placeholder="请选择"
                            size="small"
                            clearable
                            filterable
                            style="width:100%;">
                            <el-option
                                v-for="item in costTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="20" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import SelectTree from "@/components/SelectTree"

export default {
    name: 'BuildingRoomEdit',
    components: {
        SelectTree
    },
    props: {
        buildingTree: {
            type: Array,
            default () {
                return [];
            }
        },
        params: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    data(){
        return {
            checkinNumList: [
                {
                    value: 4,
                    name: '4人',
                },
                {
                    value: 6,
                    name: '6人',
                },
                {
                    value: 8,
                    name: '8人',
                },
            ],
            roomTypeList: [
                {
                    value: 1,
                    name: '男宿',
                },
                {
                    value: 2,
                    name: '女宿',
                },
                {
                    value: 3,
                    name: '夫妻房',
                },
                {
                    value: 4,
                    name: '仓库',
                },
                {
                    value: 5,
                    name: '办公室',
                },
                {
                    value: 6,
                    name: '隔离室',
                },
                {
                    value: 7,
                    name: '沃舟自用房',
                },
                {
                    value: 8,
                    name: '其他',
                },
            ],
            costTypeList: [],
            // propertyList: [
            //     {
            //         value: 1,
            //         name: '空调',
            //     },
            //     {
            //         value: 2,
            //         name: '架子床',
            //     },
            //     {
            //         value: 3,
            //         name: '柜子',
            //     },
            // ],
            form: {
                id: '',
                name: '',
                pid: '',
                costTypeId: '',
                remark: '',
                roomType: '',
                checkinNum: '',
            },
            rules: {
                pid: [{required: true, message: '楼层不能为空', trigger: 'change'}],
                name: [{required: true, message: '宿舍编号不能为空', trigger: 'blur'}],
                roomType: [{required: true, message: '宿舍类型不能为空', trigger: 'change'}],
                // property: [{required: true, message: '宿舍资产不能为空', trigger: 'change'}],
                checkinNum: [{required: true, message: '可住人数不能为空', trigger: 'blur'}],
            },
            submitting: false
        }
    },
    created() {
        this.loadCostTypeList();
        this.init();
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        ...common,
        ...verify,
        init() {
            let data = {
                id: this.params.id,
                name: this.params.name,
                pid: this.params.pid,
                costTypeId: this.params.costTypeId,
                remark: this.params.remark,
                roomType: this.params.roomType,
                checkinNum: this.params.checkinNum,
            };

            this.form = data;
        },
        loadCostTypeList(){
            http.get('v1/buildGood/list', { params: { status: 1 } }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.costTypeList = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.submitting = false;
                this.httpCatch(err);
            });
        },
        // loadProperty(){
        //     http.get('v1/goodUnit/list', this.form).then(req => {
        //         if (req.data.code == http.SUCCESS) {
        //             // this.propertyList = req.data.data;
        //         } else {
        //             this.showError(req.data.msg);
        //         }
        //     }).catch(err => {
        //         this.submitting = false;
        //         this.httpCatch(err);
        //     });
        // },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/build/edit', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('编辑房间成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.tableLoading = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>

<style scoped>
</style>
