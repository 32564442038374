<template>
    <div class="dialog-inner" style="padding-top:0;">
        <div class="datatable-wrap-1">
            <div class="datatable">
                <el-table
                    :data="tableData"
                    v-loading="tableLoading"
                    element-loading-background="rgba(255, 255, 255, 0.3)"
                    border
                    style="width: 100%">
                    <el-table-column
                        label="序号"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="supplierName"
                        label="分包单位名称">
                    </el-table-column>
                    <el-table-column
                        prop="day"
                        label="入住/退房日期"
                        width="160">
                    </el-table-column>
                    <el-table-column
                        prop="typeText"
                        label="类型"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="userName"
                        label="操作人"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="创建时间"
                        width="180">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"

export default {
    name: 'CheckLog',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            tableData: [],
            tableLoading: false,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        ...common,
        loadData() {
            let loading = this.load();
            let params = {};
            params.id = 104; // this.params.id;
            http.get('v1/build/roomCheckList', {
                params: params
            }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    data.forEach(item => {
                        item.typeText = item.type == 1 ? "入住" : "退出";
                        item.createTime = item.createTime.substr(0, 16);
                    });
                    this.tableData = data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
    }
};
</script>

<style scoped>
</style>