<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="楼栋数" prop="buildingCount">
                        <el-input type="text" v-model="form.buildingCount" size="small" maxlength="3" @blur="fieldTrim(form, 'buildingCount')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="每栋楼层数" prop="storeyCount">
                        <el-input type="text" v-model="form.storeyCount" size="small" maxlength="3" @blur="fieldTrim(form, 'storeyCount')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="每层房间数" prop="roomCount">
                        <el-input type="text" v-model="form.roomCount" size="small" maxlength="3" @blur="fieldTrim(form, 'roomCount')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="费用类型" prop="costTypeId">
                        <el-select
                            v-model="form.costTypeId"
                            placeholder="请选择"
                            size="small"
                            clearable
                            filterable
                            style="width:100%;">
                            <el-option
                                v-for="item in costTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'BatchAdd',
    data(){
        return {
            costTypeList: [],
            form: {
                buildingCount: '',
                storeyCount: '',
                roomCount: '',
                costTypeId: '',
            },
            rules: {
                buildingCount: [
                    {required: true, message: '楼栋数不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkInt, trigger: 'blur'},
                    {required: true, validator: this.checkRange, range:[1, 10], trigger: 'blur'},
                ],
                storeyCount: [
                    {required: true, message: '每栋楼层数不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkInt, trigger: 'blur'},
                    {required: true, validator: this.checkRange, range:[1, 3], trigger: 'blur'},
                ],
                roomCount: [
                    {required: true, message: '每层房间数不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkInt, trigger: 'blur'},
                ],
            },
            submitting: false
        }
    },
    created() {
        this.loadCostTypeList();
    },
    methods: {
        ...common,
        ...verify,
        loadCostTypeList(){
            http.get('v1/buildGood/list', { params: { status: 1 } }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.costTypeList = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.submitting = false;
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};
                    data.buildNum = this.form.buildingCount;
                    data.floorNum = this.form.storeyCount;
                    data.roomNum = this.form.roomCount;
                    data.costTypeId = this.form.costTypeId;
                    http.post('v1/build/initBuild', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('添加成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>

<style scoped>
</style>